<template>
  <div class="div_container">
    <v-card
        flat
        rounded
        class="mb-4 rounded shadow-0 bordered"
    >
        <v-card-text>
          <v-row dense>
            <v-col>
              <v-checkbox v-model="enable" @change="toggleForm" label="Include In Membership" hide-details dense/>
            </v-col>
          </v-row>
            <span class="text-xs red--text" v-if="note">{{ noteText }}</span>
          <template v-if="enable">
            <v-row v-for="(mData,i) in membershipData" :key="i+'d_'">
              <v-col lg="4" md="4" >
                <label for="">Membership(s)*</label>
                <v-autocomplete
                    :items="allMemberships"
                    v-model="mData.membership_ids"
                    item-value="id"
                    item-text="name"
                    outlined
                    multiple
                    dense
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    validate-on-blur
                    @change="loadMembershipPackagesNew(i)"
                    :rules="[(v) => v.length > 0 || 'Select a membership']"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggleSelectMembership(i)">
                      <v-list-item-action>
                        <v-icon
                            :color="allMemberships.length > 0 ? 'teal darken-4' : ''"
                        >{{ getMembershipServiceIcon(i) }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template
                      v-if="mData.membership_ids.length === allMemberships.length"
                      v-slot:selection="{ index }"
                  >
                    <span v-if="index === 0">All Memberships</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="text-elepsis">{{ item.name }}</span>
                    <span v-if="index === 1">,... </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col lg="3" md="3" >
                <label for="">Membership Package(s)*</label>
                <v-autocomplete
                    :items="mData.all_packages"
                    v-model="mData.membership_package_ids"
                    item-value="id"
                    item-text="name"
                    outlined
                    multiple
                    class="q-autocomplete shadow-0"
                    dense
                    background-color="#fff"
                    validate-on-blur
                    :rules="[(v) => v.length > 0 || 'Select a package']"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggleSelectMembershipPackage(i)">
                      <v-list-item-action>
                        <v-icon
                            :color="mData.all_packages.length > 0 ? 'teal darken-4' : ''"
                        >{{ getMembershipPackageServiceIcon(i) }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template
                      v-if="mData.membership_package_ids === mData.all_packages.length"
                      v-slot:selection="{ index }"
                  >
                    <span v-if="index === 0">All Services</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.name }}</span>
                    <span v-if="index === 1">, {{ item.name }}</span>
                    <span v-if="index === 2">, ...</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="2">
                <label for="">Benefit Type*</label>
                <v-select
                    required
                    :rules="[(v) => !!v || 'Benefit Type is required']"
                    v-model="mData.benefit_type"
                    outlined
                    class="q-autocomplete shadow-0"
                    dense
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-value="id"
                    item-text="name"
                    :items="[
              { id: 'P', name: 'Percentage' },
              { id: 'A', name: 'Amount' },
            ]"

                    validate-on-blur
                ></v-select>
              </v-col>
              <v-col md="2">
                <label for="">{{mData.benefit_type === 'A'
                    ? 'Amount'
                    : mData.benefit_type === 'P'
                        ? 'Percentage*'
                        : 'Promotion*'}}</label>
                <v-text-field
                    required
                    :rules="
              mData.benefit_type === 'A'
                ? [
                    (v) => !!v || 'Amount is required',
                    (v) => !isNaN(v) || 'Amount must be a number',
                  ]
                : mData.benefit_type === 'P'
                ? [
                    (v) => !!v || 'Percentage is required',
                    (v) => !isNaN(v) || 'Amount must be a number',
                    (v) => v <= 100 || 'Percentage should be less than 100',
                  ]
                : [(v) => !!v || 'Promotion is required']
            "
                    :prefix="mData.benefit_type === 'A' ? currencyCode : ''"
                    :suffix="mData.benefit_type === 'P' ? '%' : ''"
                    v-model="mData.benefit"
                    outlined
                    hide-details="auto"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    dense
                ></v-text-field>
              </v-col>
              <v-col md="1" v-if="membershipData.length > 1 && !mData.id">
                <v-btn
                    color="red"
                    @click="deleteRow(i)"
                    fab
                    x-small
                    dark
                    class="mt-6"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
<!--            <v-row  v-for="(memData,index) in membershipData" :key="index" >-->
<!--              <v-col lg="4" md="4" >-->
<!--                <label for="">Membership*</label>-->

<!--                <v-select-->
<!--                    :items="allMemberships"-->
<!--                    item-text="name"-->
<!--                    item-value="id"-->
<!--                    v-model="memData.membership_id"-->
<!--                    :rules="[(v) =>!!v || 'Membership is required']"-->
<!--                    validate-on-blur-->
<!--                    outlined-->
<!--                    :menu-props="{bottom: true,offsetY: true,}"-->
<!--                    background-color="#fff"-->
<!--                    @change="loadMembershipPackages(memData.membership_id,index)"-->
<!--                    class="q-autocomplete shadow-0"-->
<!--                    dense-->
<!--                    hide-details="auto"-->
<!--                ></v-select>-->
<!--              </v-col>-->
<!--              <v-col lg="3" md="3">-->
<!--                <label for="">Membership package*</label>-->
<!--                <v-select-->
<!--                    :items="memData.all_packages"-->
<!--                    item-text="name"-->
<!--                    item-value="id"-->
<!--                    :disabled="!memData.membership_id"-->
<!--                    v-model="memData.membership_package_id"-->
<!--                    :rules="[(v) =>!!v || 'Membership is required']"-->
<!--                    validate-on-blur-->
<!--                    outlined-->
<!--                    :menu-props="{bottom: true,offsetY: true,}"-->
<!--                    background-color="#fff"-->
<!--                    class="q-autocomplete shadow-0"-->
<!--                    dense-->
<!--                    hide-details="auto"-->
<!--                ></v-select>-->
<!--              </v-col>-->
<!--              <v-col md="2">-->
<!--                <label for="">Benefit Type*</label>-->
<!--                <v-select-->
<!--                    required-->
<!--                    :rules="[(v) => !!v || 'Benefit Type is required']"-->
<!--                    v-model="memData.benefit_type"-->
<!--                    outlined-->
<!--                    class="q-autocomplete shadow-0"-->
<!--                    dense-->
<!--                    hide-details="auto"-->
<!--                    :menu-props="{ bottom: true, offsetY: true }"-->
<!--                    item-value="id"-->
<!--                    item-text="name"-->
<!--                    :items="[-->
<!--              { id: 'P', name: 'Percentage' },-->
<!--              { id: 'A', name: 'Amount' },-->
<!--            ]"-->

<!--                    validate-on-blur-->
<!--                ></v-select>-->
<!--              </v-col>-->
<!--              <v-col md="2">-->
<!--                <label for="">{{memData.benefit_type === 'A'-->
<!--                    ? 'Amount'-->
<!--                    : memData.benefit_type === 'P'-->
<!--                        ? 'Percentage*'-->
<!--                        : 'Promotion*'}}</label>-->
<!--                <v-text-field-->
<!--                    required-->
<!--                    :rules="-->
<!--              memData.benefit_type === 'A'-->
<!--                ? [-->
<!--                    (v) => !!v || 'Amount is required',-->
<!--                    (v) => !isNaN(v) || 'Amount must be a number',-->
<!--                  ]-->
<!--                : memData.benefit_type === 'P'-->
<!--                ? [-->
<!--                    (v) => !!v || 'Percentage is required',-->
<!--                    (v) => !isNaN(v) || 'Amount must be a number',-->
<!--                    (v) => v <= 100 || 'Percentage should be less than 100',-->
<!--                  ]-->
<!--                : [(v) => !!v || 'Promotion is required']-->
<!--            "-->
<!--                    :prefix="memData.benefit_type === 'A' ? currencyCode : ''"-->
<!--                    :suffix="memData.benefit_type === 'P' ? '%' : ''"-->
<!--                    v-model="memData.benefit"-->
<!--                    outlined-->
<!--                    hide-details="auto"-->
<!--                    validate-on-blur-->
<!--                    class="q-text-field shadow-0"-->
<!--                    dense-->
<!--                ></v-text-field>-->
<!--              </v-col>-->
<!--              <v-col md="1" v-if="membershipData.length > 1 && !memData.id">-->
<!--                <v-btn-->
<!--                    color="red"-->
<!--                    @click="deleteRow(index)"-->
<!--                    fab-->
<!--                    x-small-->
<!--                    dark-->
<!--                    class="mt-6"-->
<!--                >-->
<!--                  <v-icon>mdi-delete</v-icon>-->
<!--                </v-btn>-->
<!--              </v-col>-->
<!--            </v-row>-->
          </template>


          <v-row dense v-if="enable">
            <v-col>
              <p  class=" text-base text-neon  font-semibold pointer"   @click="addMembershipRow"  style="cursor: pointer;">+Add Row</p>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>


    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    note: {type: Boolean, default: false},
    noteText: {type: String, default: ''},
    existingMembershipData: {
      type: Array, default: () => {
        return []
      }
    },
  },
  watch: {
    existingMembershipData: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.populateOldData();
        }
      },
      immediate: true
    },
    enable:{
      handler(){
        this.updateFormData();
      }
    },
    membershipData: {
      handler(newVal) {
        newVal.forEach(item => {
          this.$watch(() => item, () => {
            this.updateFormData();
          }, { deep: true });
        });
      },
      immediate: true
    }
  },

  data() {
    return {
      enable:false,
      allMemberships:[],
      deletedRows:[],
      forceEnable :false,
      membershipData: [
        {
          membership_id: null,
          membership_ids:[],
          membership_package_ids:[],
          all_packages:[],
          membership_package_id: null,
          benefit_type: null,
          benefit: null,
          workshop_membership_configuration_id: null,
        }
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    }
  },
  mounted() {
    this.loadAllMemberships(true);
  },
  computed: {},
  methods: {
    toggleForm(){
      if(!this.enable){
        this.membershipData =  [
          {
            membership_id: null,
            membership_ids:[],
            membership_package_ids:[],
            all_packages:[],
            membership_package_id: null,
            benefit_type: null,
            benefit: null,
            workshop_membership_configuration_id: null,
          }
        ]
      }
    },
    getMembershipServiceIcon(i) {
      if (this.membershipData[i].membership_ids.length === 0) return "mdi-checkbox-blank-outline";
      if (this.allMemberships.length == this.membershipData[i].membership_ids.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    getMembershipPackageServiceIcon(i) {
      if (this.membershipData[i].membership_package_ids.length === 0) return "mdi-checkbox-blank-outline";
      if (this.membershipData[i].all_packages.length == this.membershipData[i].membership_package_ids.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },

    toggleSelectMembership(i) {
      this.$nextTick(() => {
        if (this.membershipData[i].membership_ids.length === this.allMemberships.length) {
          this.membershipData[i].membership_ids = [];
        } else {
          this.membershipData[i].membership_ids = this.allMemberships.map(
              (item) => item.id
          );
        }
      });
      setTimeout(() => {
        this.loadMembershipPackagesNew(i);
      });
    },
    toggleSelectMembershipPackage(i) {
      this.$nextTick(() => {
        if (this.membershipData[i].membership_package_ids.length === this.membershipData[i].all_packages.length) {
          this.membershipData[i].membership_package_ids = [];
        } else {
          this.membershipData[i].membership_package_ids = this.membershipData[i].all_packages.map(
              (item) => item.id
          );
        }
      });
    },

    updateFormData(){
      this.$emit('updateForm',{ enable:this.enable ,data:this.membershipData, deleted: this.deletedRows});
    },
    populateOldData(){
      this.membershipData = [];
      if(this.existingMembershipData.length > 0){
        this.enable = true;
        this.forceEnable = true;
      }

      this.existingMembershipData.forEach((item,index) => {
        this.membershipData.push({
          workshop_membership_configuration_id: item.workshop_membership_configuration_id,
          membership_id: item.membership_id,
          all_packages:[],
          membership_ids:item.membership_id_g.split(',').map((id) => {
            return parseInt(id);
          }),
          membership_package_ids:item.membership_package_id_g.split(',').map((id) => {
            return parseInt(id);
          }),
          membership_package_id: item.membership_package_id,
          benefit_type: item.benefit_type,
          benefit: item.benefit,
          benefit_id:item.benefit_id,
          id:item.id,
        });
        this.loadMembershipPackagesNew(index);

        this.membershipData[index].membership_package_id= item.membership_package_id;
        this.membershipData[index].membership_package_ids= item.membership_package_id_g.split(',').map((id) => {
          return parseInt(id);
        });
      });
      this.$forceUpdate()
    },
    deleteRow(index){
      let data = this.membershipData[index];
      if (data.membership_package_id == null) {
        this.membershipData.splice(index, 1);
        this.$forceUpdate();
        return true;
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this membership row?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "membership",
        };
      }
    },
    confirmActions(data) {
      if(data.type === 'membership'){
        this.deletedRows.push(this.membershipData[data.id])
        this.membershipData.splice(data.id, 1);
      }
    },
    loadAllMemberships(check = false){
      this.$http
          .get("venues/workshops/membership/all")
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.allMemberships = response.data.data;
            }
            if(check){
              if(this.existingMembershipData.length > 0){
                this.populateOldData();
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    loadMembershipPackages(id,index){
      this.showLoader('Loading packages');

      // let ids = this.membershipData[id].membership_ids;
      this.$http
          // .post(`venues/workshops/membership/packages`,ids)
          .get(`venues/workshops/membership/packages/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.membershipData[index].all_packages = response.data.data;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
          this.hideLoader();
      });
    },
    loadMembershipPackagesNew(index){

      this.showLoader('Loading packages');
      let ids = this.membershipData[index].membership_ids;

      this.$http
          .get(`venues/workshops/membership/packages?ids=${ids.join(',')}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.membershipData[index].all_packages = response.data.data;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
          this.hideLoader();
      });
    },
    addMembershipRow(){
      this.membershipData.push(
          {
            membership_id: null,
            workshop_membership_configuration_id:null,
            membership_package_id: null,
            benefit_type: null,
            benefit: null,
            membership_ids:[],
            all_packages:[],
            membership_package_ids:[]
          }
      )
    }
  }
}
</script>
<style scoped>

.div_container{
  width: 100%;
  margin: 2rem 10px 0;
}


.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 85%;
  overflow: hidden;
}
</style>